@if (authenticated && user.userId) {
<ce-header [title]="title">
  @if (portalClassificationId !== 4 && portalClassificationId !== 5) {
  <ce-header-menu
    label="Dashboard"
    (clickEvent)="navigate('dashboard')"
  >
  </ce-header-menu>
  }
  <ce-header-menu
    label="Search"
    (clickEvent)="navigate('search')"
  >
  </ce-header-menu>
  <ce-header-menu-standout
    [label]="userName"
    iconName="icon-logged-in"
  >
    <ce-header-menu-item
      label="Log Out"
      (clickEvent)="logout()"
    >
    </ce-header-menu-item>
  </ce-header-menu-standout>
</ce-header>
} @if (!authenticated || !user.userId) {
<ce-header
  class="header-login"
  [title]="title"
  logoUrl="/"
>
</ce-header>
}
<!-- <div class="header-container">
<ce-header
  *ngIf="authenticated && user.userId"
  [title]="title"
  [activeIndex]="activeTabIndex"
  logoUrl="/"
  >
  <ce-header-menu
    *ngIf="portalClassificationId !== 4 && portalClassificationId !== 5"
    label="Dashboard"
    (clickEvent)="navigate('dashboard')"
    >
  </ce-header-menu>

  <ce-header-menu
    label="Search"
    (clickEvent)="navigate('search')"
    >
  </ce-header-menu>

  <ce-header-menu-standout
    [label]="userName"
    iconName="icon-logged-in"
    >
    <ce-header-menu-item
      label="Log Out"
      (clickEvent)="logout()"
      >
    </ce-header-menu-item>
  </ce-header-menu-standout>
</ce-header>

<ce-header
  *ngIf="!authenticated || !user.userId"
  class="header-login"
  [title]="title"
  logoUrl="/"
  >
</ce-header>
</div> -->

<div [ngClass]="{'main-container': currentRoute !== 'search'}">
  <router-outlet></router-outlet>
</div>

<div class="footer-container">
  <ce-footer>
    <ce-footer-item
      label="Contact Us"
      (clickEvent)="navigate('contactUs')"
    ></ce-footer-item>
    <ce-footer-item
      label="Using Central Connect"
      (clickEvent)="navigate('userManual')"
    ></ce-footer-item>
    <ce-footer-item
      label="Troubleshooting"
      (clickEvent)="navigate('troubleshooting')"
    ></ce-footer-item>
    <ce-footer-item
      label="Legal Information & Terms of Use"
      (clickEvent)="navigate('legalInfo')"
    ></ce-footer-item>
  </ce-footer>
</div>

<!-- Install App on Home screen Modal -->
<ce-modal
  [visible]="showInstallModal && onDashboard()"
  title="Would you like to install this app on your home screen?"
>
  <ce-modal-body>
    This will make it easier for you to access CC and will unlock more features.
  </ce-modal-body>
  <ce-modal-footer>
    <div class="install-prompt-buttons">
      <ce-button
        type="secondary"
        label="Cancel"
        size="large"
        (clickEvent)="closeInstallModal()"
        class="col-6"
      >
      </ce-button>
      <ce-button
        type="primary"
        label="Install"
        size="large"
        (clickEvent)="showInstallPrompt()"
        class="col-6"
      >
      </ce-button>
    </div>
  </ce-modal-footer>
</ce-modal>
